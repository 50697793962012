import * as React from "react";

export function Head() {
	return (
		<script>
			{`
        (() => {
          const url = window.location.href;
          if (url.includes("/index.html")) {
              window.history.replaceState("", "", url.replace("/index.html", ""));
          }
        })();
      `}
		</script>
	);
}
