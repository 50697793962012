import {
	GatsbyImage,
	getImage,
	IGatsbyImageData,
	ImageDataLike
} from "gatsby-plugin-image";
import { Link } from "gatsby-plugin-react-i18next";
import React from "react";

export type Props = {
	items: Queries.NewsCollectionQuery["allNews"]["edges"];
	children?: React.ReactNode;
};

export const NewsList = ({ items, children }: Props) => {
	return (
		<div className="px-4">
			<ul className="space-y-4">
				{items.map(({ node }) => (
					<li key={node.id}>
						<Link
							to={`/announcements/${node.frontmatter?.slug}`}
							className="flex px-2.5 py-5 lg:px-4 lg:py-3 bg-[linear-gradient(to_right,_#830372_12.22%,_#111861_86.22%)]"
						>
							<div className="text-center min-w-[85px] w-[24%] shrink-0 mr-3 lg:mr-9 lg:max-w-[225px]">
								<GatsbyImage
									image={
										getImage(
											node.frontmatter?.cover as ImageDataLike
										) as IGatsbyImageData
									}
									alt="Cover image"
								/>
								<time className="block text-sm font-bold text-pallet-sky lg:hidden">
									{node.frontmatter?.date}
								</time>
							</div>
							<div>
								<time className="hidden mb-1 text-sm text-pallet-sky lg:block">
									{node.frontmatter?.date}
								</time>
								<h2 className="font-bold lg:text-lg">
									{node.frontmatter?.title}
								</h2>
								<p className="mt-1 text-xs text-pallet-purple lg:text-sm line-clamp-2">
									{node.frontmatter?.summary}
								</p>
							</div>
						</Link>
					</li>
				))}
			</ul>
			{children}
		</div>
	);
};
