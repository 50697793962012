import cx from "classnames";
import { CtaButton } from "components/CtaButton";
import { NewsList } from "components/NewsList";
import { SectionHeading } from "components/SectionHeading";
import { graphql, PageProps } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { useState } from "react";

const LAST = 6;

export default function News({ data }: PageProps<Queries.NewsCollectionQuery>) {
	const { t } = useTranslation();
	const allNews = data.allNews.edges;
	const [page, setPage] = useState(1);
	const handleViewMore = () => {
		if (page * LAST < allNews.length) {
			setPage(page + 1);
		}
	};

	return (
		<div className="pt-12 pb-24 text-white pattern-bg lg:pt-20 lg:pb-72">
			<div className="mx-auto max-w-5xl">
				<SectionHeading className="justify-center mb-12 w-full lg:mb-28">
					{t("title.news")}
				</SectionHeading>
				<NewsList items={allNews.slice(0, LAST * page)}>
					<div
						className={cx("text-center mt-24 lg:mt-44", {
							invisible: page * LAST >= allNews.length,
						})}
					>
						<CtaButton onClick={handleViewMore} className="mt-1">
							{t("button.more")}
						</CtaButton>
					</div>
				</NewsList>
			</div>
		</div>
	);
}

export const query = graphql`
	fragment NewsFragment on MarkdownRemark {
		id
		frontmatter {
			title
			type
			summary
			slug
			cover {
				childImageSharp {
					gatsbyImageData(
						layout: CONSTRAINED
						placeholder: BLURRED
						sizes: "33vw, (min-width: 768px) 225px"
					)
				}
			}
			date(formatString: "YYYY.MM.DD")
		}
	}
	query NewsCollection($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					...Translation
				}
			}
		}
		allNews: allMarkdownRemark(
			filter: {
				frontmatter: { type: { eq: "announcement" } }
				fields: { language: { eq: $language } }
			}
			sort: { fields: frontmatter___date, order: DESC }
		) {
			edges {
				node {
					...NewsFragment
				}
			}
		}
	}
`;

export { Head } from "components/GCSRewriteURL";
